.lia-user {
  &-rank {
    margin: 4px 0;
    display: inline-flex;
    align-items: center;
  }

  &-badges-list {
    display: flex;
    margin-top: 15px;
    margin-bottom: 3px;
    gap: 10px;
  }
}
